const projdata = [
    {
	id: 4,
	title: "Applying 'home-away' to UI design patterns",
	date: "Spring 2020",
	publication: "<a href='https://doi.org/10.1145/3424771.3424780' class='external link' target='_blank'>EuroPLoP Paper</a>",
	description: "",
	findings: "Our analysis suggests that people's behaviour depends on their distance from the object they are protecting (e.g. home): when home they devise and implement strategies to dissuade attackers, when leaving they perform security checks, while away they monitor their home if they can, and when returning they verify the integrity of their security.",
	discussion: "This 'home-away' strategy seems designed to optimize resources relating to the allocation of attention. We think there are important parallels with problems faced in computer security, and that designing software with these tendencies in mind might be fruitful."
    },
    {
	id: 3,
	title: "Everyday security practices: The 'home-away' cycle",
	date: "Fall 2019",
	publication: "in press",
	description: "Focus group with attendees of 2019 Conference on Pattern Languages of Programs on practices people use to keep their home and belongings safe.",
	findings: "Our analysis suggests that people's behaviour depends on their distance from the object they are protecting (e.g. home): when home they devise and implement strategies to dissuade attackers, when leaving they perform security checks, while away they monitor their home if they can, and when returning they verify the integrity of their security.",
	discussion: "This 'home-away' strategy seems designed to optimize resources relating to the allocation of attention. We think there are important parallels with problems faced in computer security, and that designing software with these tendencies in mind might be fruitful."
    },
    {
	id: 2,
	title: "Eliciting mental models of malware",
	date: "Fall 2018",
	publication: "<a href='https://doi.org/10.1109/eCrime47957.2019.9037597' class='external link' target='_blank'>eCrime Paper</a>",
	description: "User study where 40 participants drew diagrams of malware and legitimate software, and completed a questionnaire to give us some insight into their mental models of malware, and software in general.",
	findings: "The diagrams of malware were generally less detailed than those of legitimate software, suggesting a lesser working knowledge of malware. Malware was depicted as being totally out of users' control, compared to legitimate software which was totally under users' control.",
	discussion: "Malware is indeed less easy to control by design, but users can in principle have a great degree of control over it by understanding how it works. Better mental models of malware will better enable users to defend themselves against it."
    },
    {
	id: 1,
	title: "Mental model misalignment in VeraCrypt",
	date: "Fall 2018",
	publication: "<a href='https://dx.doi.org/10.1007/978-3-030-23522-2_27' class='external link' target='_blank'>HCII Poster</a>",
	description: "User study testing the effect of a verbally-delivered metaphor on usability of the notoriously difficult-to-use VeraCrypt",
	findings: "Users advanced further after listening to description of VeraCrypt's encrypted as USB drives",
	discussion: "For us, this suggested promise in an approach to software targeting mental models"
    }
];

export default projdata;
