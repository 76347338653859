<template>

    <div class="projblurb"> Brief descriptions of projects led by me since starting my PhD </div>
    
    <div v-for="proj in projdata" v-bind:key="proj">
	<Project v-bind="proj"> </Project>

</div>

</template>

<script>

 import Project from '@/components/Project.vue';
 import projdata from '@/data/projectdata.js';

 export default {
     components: {
	 Project
     },
     data() {
	 return{
	     projdata	     
	 }
     }
 }

</script>

<style>
 .projblurb {
     text-align: left;
     margin-top: 20px;
 }
</style>
