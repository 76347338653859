<template>
    <div class="pubproj">
	<ul>
	    <li><b> {{ title }} </b></li>
	    <li> {{ date }} </li>
	    <li v-html=publication></li>
	</ul>
	<ul class="projlist">
	    <li> {{ description }} </li>
	    <li> {{ findings }} </li>
	    <li> {{ discussion }} </li>
	</ul>
    </div>

</template>

<script>

 export default {

     props: {
	 title: String,
	 date: String,
	 publication: String,
	 description: String,
	 findings: String,
	 discussion: String
     }
 }

</script>

<style scoped>

 .projlist {
     list-style-type: disc;
     list-style-position: inside;
 }

 ul {
     margin: 1ex 0px;
 }

 .projlist li {
     margin: 4pt 0;
 }

</style>
